(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

angular.module('emailValidation', ['ngCookies', 'satellizer', 'pascalprecht.translate', 'restangular', 'ngSweetAlert', 'helpers']);

angular.module('emailValidation').config(['RestangularProvider', function (RestangularProvider) {
    RestangularProvider.setBaseUrl('/api/v1'); // check errorInterceptor
    RestangularProvider.setDefaultHeaders({
        'Content-Type': 'application/json'
    });
}]).run(['$rootScope', '$log', '$http', '$auth', '$translate', '$timeout', '$window', 'Restangular', 'queueService', 'errorService', 'spinnerService', function ($rootScope, $log, $http, $auth, $translate, $timeout, $window, Restangular, queueService, errorService, spinnerService) {

    Restangular.addFullRequestInterceptor(function (element, method, name, url, headers) {
        $log.debug(method + " " + name + ": start");
        $log.debug(element);

        var token = $auth.getToken();
        if (!_.isNull(token)) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        headers['Content-Language'] = $translate.use();
        return {
            "headers": headers
        };
    });

    Restangular.addResponseInterceptor(function (data, method, name) {
        $log.debug(method + " " + name + ": success");
        $log.debug(data);
        return data;
    });

    Restangular.addErrorInterceptor(function (data) {
        if (angular.isUndefined(data.config)) {
            return;
        }
        $log.debug(data.config.method + " " + data.config.url + ": error");
        $log.debug(data.data);

        spinnerService.endAll();

        if (angular.isObject(data.data)) {
            errorService.clear();
            errorService.addFromServer(data.data);
        }
        return data.data;
    });
}]);

/**
 * @author tomas
 */
angular.module('emailValidation').service('emailValidationService', ['$log', '$window', '$timeout', '$translate', 'Restangular', 'SweetAlert', 'errorService', 'spinnerService', 'queueService', function ($log, $window, $timeout, $translate, Restangular, SweetAlert, errorService, spinnerService, queueService) {
    $log.debug("emailValidationService initialized");

    var isUserVerified = false;

    var get = function get(callback) {
        spinnerService.start('user', 'get');
        queueService.add(function () {
            Restangular.all('auth').get('me').then(function (response) {
                isUserVerified = response.verified;
                spinnerService.end('user', 'get');
                callback(isUserVerified);
            }, function () {
                callback(false);
                spinnerService.end('user', 'get');
            });
        });
    };

    var resendValidationEmail = function resendValidationEmail(isStudio, callback) {
        spinnerService.start('user', 'resendValidation');
        queueService.add(function () {
            Restangular.all('resend/validation').post({
                "studio": isStudio
            }).then(function () {
                spinnerService.end('user', 'resendValidation');
                SweetAlert.show({
                    title: $translate.instant('validation.EMAIL_RESENT'),
                    type: 'success',
                    confirmButtonColor: "#333787"
                });
                if (angular.isFunction(callback)) {
                    callback.call();
                }
            });
        });
    };

    var isVerified = function isVerified() {
        return isUserVerified;
    };

    // TODO: maybe change this later, to require verification
    var checkIfVerified = function checkIfVerified() {
        // if (!isVerified()) {
        //     $timeout(() => {
        //         SweetAlert.show({
        //             title: $translate.instant('validation.NOT_VERIFIED'),
        //             type: 'error',
        //             confirmButtonColor: "#333787"
        //         });
        //     }, 500);
        // }
        // return isUserVerified;
        return true;
    };

    return {
        'get': get,
        'isVerified': isVerified,
        'checkIfVerified': checkIfVerified,
        'resendValidationEmail': resendValidationEmail
    };
}]);

},{}]},{},[1]);
